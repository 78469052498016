import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const NavLink = ({ url, className, text }) => (
    <li className={`nav-li ${className}`}>
        <Link to={`/${url}`} activeClassName="active" className="nav-link">
            {text}
        </Link>
    </li>
);

NavLink.propTypes = {
    url: PropTypes.string,
    className: PropTypes.string,
    text: PropTypes.string,
};

const Header = () => (
    <div className="container">
        <header className="header row">
            <div className="logo-container col-xs-12 col-sm-6">
                <h1 className="logo">Haircut One-Hundred</h1>
            </div>
            <div className="navigation col-sm-6">
                <ul className="nav-menu list-plain">
                    <NavLink
                        url="services/"
                        className="services"
                        text="Salon Services"
                    />
                    <NavLink
                        url="location/"
                        className="location"
                        text="Location"
                    />
                    <NavLink
                        url="gallery/"
                        className="gallery"
                        text="Gallery"
                    />
                    <NavLink url="about/" className="about" text="About" />
                </ul>
            </div>
        </header>
    </div>
);

export default Header;
