import React from 'react';

const currentYear = new Date().getFullYear();

const Footer = () => (
    <div className="fluid-container footer-container">
        <div className="container">
            <footer className="footer row">
                <div className="col-sm-6">
                    <p className="copyright hidden-xs">
                        &copy; Copyright 2015-<span className="year-current">
                            {currentYear}
                        </span>{' '}
                        -{' '}
                        <span className="text-uppercase">
                            Haircut One-Hundred
                        </span>
                    </p>
                </div>
                <div className="col-sm-6 vcard-container">
                    <div className="vcard">
                        <span className="adr">
                            <span className="street-address">
                                13 Eagle Plaza
                            </span>{' '}
                            <span className="locality">Voorhees</span>,{' '}
                            <span className="region">NJ</span>{' '}
                            <span className="postal-code">08043</span>
                        </span>
                        <span className="addr-separator">|</span>
                        <span className="tel">856.346.1416</span>
                    </div>
                </div>
            </footer>
        </div>
    </div>
);

export default Footer;
