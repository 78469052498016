import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import Header from './header';
import Footer from './footer';
import 'styles/styles.styl';

// const Layout = ({ children, data, history, location, match }) => (
const Layout = ({ children }) => (
    <StaticQuery
        query={graphql`
            query LayoutQuery {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `}
        render={data => (
            <div>
                <Helmet
                    title={data.site.siteMetadata.title}
                    meta={[
                        { name: 'description', content: 'Haircut One-Hundred' },
                        {
                            name: 'keywords',
                            content: 'haircut, voorhees, salon, hair salon',
                        },
                    ]}
                >
                    <link
                        href="//fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700|Open+Sans+Condensed:300"
                        rel="stylesheet"
                    />
                    <link
                        href="//maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
                        rel="stylesheet"
                    />
                    <link
                        href="//unpkg.com/flickity@2/dist/flickity.min.css"
                        rel="stylesheet"
                    />
                </Helmet>
                <Header siteTitle={data.site.siteMetadata.title} />
                {children}
                <Footer />
            </div>
        )}
    />
);

Layout.propTypes = {
    children: PropTypes.shape({}),
};

export default Layout;
